form {
  margin-top: 16px;
  margin-bottom: 32px;

  .row {
    display: flex;
    align-items: center;

    &.errors {
      color: var(--red);
    }

    @include max-medium-screen {
      flex-direction: column;
    }

    .input {
      @include min-medium-screen {
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }

  button[type='submit'] {
    @include max-medium-screen {
      width: 100%;
    }
  }
}

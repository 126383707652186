.radio-button-block {
  padding: 8px 20px 8px 0;
  cursor: pointer;
  display: flex;

  &.border {
    border: 1px solid var(--grey);
    border-radius: 10px;
    margin-top: 16px;
  }

  &.active {
    &.border {
      border-color: var(--green);
    }

    .radio-button {
      .fill {
        display: initial;
      }
    }
  }

  .left,
  .right {
    width: 40px;
    display: flex;
    justify-content: center;
    padding: 4px;
  }

  .radio-button {
    border-radius: 50%;
    content: '';
    width: 20px;
    height: 20px;
    border: 1px solid var(--black);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .fill {
      display: none;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: var(--black);
      content: '';
    }
  }
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 300;
  src: local('Titillium Web Light'), local('TitilliumWeb-Light'),
    url(fonts/Titillium-Web/TitilliumWeb-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src: local('Titillium Web Regular'), local('TitilliumWeb-Regular'),
    url(fonts/Titillium-Web/TitilliumWeb-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 600;
  src: local('Titillium Web SemiBold'), local('TitilliumWeb-SemiBold'),
    url(fonts/Titillium-Web/TitilliumWeb-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src: local('Titillium Web Bold'), local('TitilliumWeb-Bold'),
    url(fonts/Titillium-Web/TitilliumWeb-Bold.ttf) format('truetype');
}

body {
  color: var(--black);
}

* {
  font-family: 'Titillium Web', Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  margin: 0;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 16px;
  color: var(--bodytext);
}

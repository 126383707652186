.overview-step {
  h2 {
    margin-bottom: 12px;
  }

  h3 {
    color: var(--black);
    font-size: 18px;
    display: inline-block;
  }

  h4 {
    color: var(--bodytext);
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .sections {
    > div {
      padding-top: 16px;
      padding-bottom: 16px;
      border-bottom: 1px solid var(--grey);

      &:nth-last-child(3) {
        border-bottom-color: var(--linework);
      }

      &:nth-last-child(2) {
        border-bottom-color: var(--black);
      }

      &:last-child {
        border-bottom: none;
      }

      > h3,
      > .title {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .vat-line {
    margin-top: 8px;
  }

  .vat-text {
    font-size: 14px;
    color: var(--bodytext);
    margin-left: 8px;
  }

  .contact-company-details {
    .details-blocks {
      padding-left: 16px;
      display: flex;
      justify-content: space-between;

      @include max-small-screen {
        flex-direction: column;
      }
    }
  }

  .edit-details-button {
    margin-top: 16px;
  }

  .down-payment {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
    color: var(--bodytext);
  }

  .vat-info-icon {
    margin-left: 8px;
  }

  .price-row,
  .service-item-row {
    margin-top: 8px;
    padding-left: 16px;
    display: flex;
    justify-content: space-between;

    :first-child {
      color: var(--bodytext);
      font-weight: 600;
    }
  }

  .discount {
    color: var(--green);
    margin-top: 8px;
  }
}

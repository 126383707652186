.transit-plates-modal {
  .modal-box {
    padding: 16px;

    .title-bar {
      padding-left: 16px;
      padding-top: 8px;

      .title {
        font-size: 24px;
      }
    }

    .content {
      padding-left: 16px;

      .sub-title {
        font-size: 16px;
        color: var(--black);
        opacity: 1;
      }

      .transit-plates-block {
        margin-top: 32px;

        .title {
          font-weight: 600;
          padding-bottom: 12px;
        }

        .checks {
          padding-bottom: 16px;

          .xmark-icon,
          .check-icon {
            padding-right: 9px;
          }

          .xmark-icon {
            color: var(--red);
            padding-left: 2px;
          }

          .check-icon {
            color: var(--green);
          }
        }
      }
    }
  }
}

.phone-number-block {
  width: 100%;

  @include min-medium-screen {
    max-width: 350px;
  }

  &.required {
    label {
      &::after {
        content: ' *';
      }
    }
  }

  label {
    height: 15px;
    font-size: 16px;
    background: var(--white);
    display: inline-block;
    margin-bottom: 16px;
  }

  .phone-number {
    display: flex;

    .combobox {
      flex: 1 0 120px;

      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        &:disabled {
          background-color: var(--grey);
        }
      }

      ul {
        width: auto;
      }
    }

    .input {
      .phone-number-input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
      }
    }
  }
}

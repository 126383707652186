header.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
  z-index: 90;

  @include max-small-screen {
    max-height: 65px;
  }

  .header-content {
    max-width: $max-layout-width;
    width: 100%;
    margin: 0 auto;
    display: grid;

    .available-hours {
      display: flex;
      grid-column: 3;
      justify-self: end;
      padding: 0 16px;

      @include max-small-screen {
        display: none;
      }

      .tel-icon {
        font-size: 32px;
        margin-right: 8px;
        color: var(--green);
      }

      .contact-info {
        color: var(--black);
        font-size: 14px;

        .tel-number a {
          font-weight: 800;
          line-height: 1.5;
          font-size: 14px;
          color: var(--black);
          text-decoration: none;
        }
      }

      .d-flex {
        display: flex;
        justify-content: flex-start;
      }
    }
  }

  .header-brand {
    background-image: url('../assets/images/baslogo.svg');
    background-repeat: no-repeat;
    display: inline-block;
    padding-top: -10px;
    padding-bottom: -10px;
    margin-right: 16px;
    font-size: 24px;
    line-height: inherit;
    white-space: nowrap;
    width: 200px;
    text-decoration: none;
    color: var(--black);
    height: 50px;
    position: relative;
    top: 12px;

    @include max-small-screen {
      max-width: 180px;
      margin-left: 10px;
    }

    small {
      font-size: 15px;
      font-weight: 400;
      position: relative;
      right: -105px;
      bottom: -10px;
      font-style: italic;

      @include max-small-screen {
        display: none;
      }
    }
  }
}

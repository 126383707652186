.request-transport-quote-modal {
  textarea {
    height: 10rem;
    resize: none;
  }

  .chat {
    display: flex;
    flex-direction: column;

    .message {
      max-width: 600px;
      margin-bottom: 32px;
      display: flex;
      align-items: center;

      .content {
        padding: 32px;

        .icon {
          font-size: 2rem;
        }
      }

      &.right {
        align-self: flex-end;

        .content {
          background-color: var(--green);
          color: var(--white);
        }

        &::after {
          display: block;
          content: '';
          flex: 0 0 48px;
          height: 48px;
          background-color: var(--green);
          clip-path: polygon(0 0, 0 100%, 100% 50%);
        }
      }

      &.left {
        align-self: flex-start;

        .content {
          background-color: var(--green-notification);
        }

        &::before {
          display: block;
          content: '';
          flex: 0 0 48px;
          height: 48px;
          background-color: var(--green-notification);
          clip-path: polygon(0 50%, 100% 100%, 100% 0);
        }
      }
    }
  }
}

.documents-block {
  align-items: stretch;
  flex-wrap: wrap;
  margin-top: 15px;
  display: flex;
  width: 100%;

  .document-title {
    font-weight: 600;
  }

  .document-subtitle {
    color: var(--green);
  }

  .document-button {
    border: 1px solid var(--grey);
    padding: 18px 23px 10px 15px;
    margin-bottom: 20px;
    position: relative;
    border-radius: 5px;
    cursor: pointer;
    line-height: 1;
    display: flex;
    width: 100%;

    @include min-medium-screen {
      transition: box-shadow 0.15s ease-in-out;

      &:hover {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
      }

      &.not-downloadable {
        box-shadow: none;
        cursor: default;
      }
    }

    @include min-medium-screen {
      max-width: 338px;
    }

    &:nth-child(2n) {
      @include min-large-screen {
        margin-left: 15px;
      }
    }

    .icons {
      display: inline-flex;
      position: relative;
      max-width: 35px;
      min-width: 35px;

      &-text {
        font-size: 1.82rem;
      }

      &-check {
        background-color: var(--green);
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        position: absolute;
        display: flex;
        color: var(--white);
        height: 20px;
        width: 20px;
        left: 10px;
        top: -6px;

        .icon {
          position: relative;
          font-size: 0.7rem;
          top: 1px;
        }
      }
    }

    .text {
      flex-grow: 1;

      > div:last-child {
        font-size: 0.87rem;
        line-height: 1.5;
      }
    }

    &-block {
      justify-content: flex-end;
      align-items: flex-start;
      margin-top: -2px;
      min-width: 50px;
      max-width: 50px;
      display: flex;

      .button {
        position: static;
        padding: 0;

        &:hover {
          background-color: transparent;
          border-color: transparent;
        }

        &:before {
          position: absolute;
          height: 100%;
          width: 100%;
          content: '';
          z-index: 2;
          left: 0;
          top: 0;
        }

        .icon {
          font-size: 1.5rem;
          margin: 0;
        }

        .loader-wrapper {
          position: relative;
          margin-top: -100%;
        }
      }
    }

    .price {
      text-align: right;
      font-weight: 600;
      min-width: 70px;
    }
  }
}

.payment-step {
  .reservation-info {
    color: var(--green);
  }

  .general-term-check {
    margin-top: 32px;
    font-size: 14px;

    .sales-contract-check-box {
      .download-sales-contract {
        background-color: transparent;
        font-weight: 600;
        cursor: pointer;
        color: var(--blue);
        height: 20px;
        padding-left: 0;
        padding-right: 0;
        border-color: transparent;
        text-decoration: underline;
        text-align: left;
        font-size: 14px;

        &:hover {
          background-color: transparent;
        }
      }
    }

    a {
      color: var(--blue);
      font-weight: 600;
    }
  }

  .secure-payment {
    margin-top: 8px;
    display: flex;
    align-items: center;

    .icon {
      color: var(--green);
      margin-right: 8px;
      font-size: 1.2rem;
    }
  }

  .payment-error-modal {
    .modal-box {
      padding: 16px;
      width: 50%;

      .title-bar {
        padding-left: 16px;
        padding-top: 8px;

        .title {
          font-size: 24px;
        }
      }

      .error-footer {
        margin-top: 16px;
        float: right;

        .return {
          margin-right: 10px;
        }
      }
    }
  }
}

input {
  border-radius: 4px;
  border: 1px solid var(--grey);
  padding-left: 16px;
  padding-right: 16px;
  height: $input-height;
  width: 100%;

  &:focus::placeholder {
    color: transparent;
  }

  &:disabled {
    background-color: var(--grey);
  }
}

textarea {
  border-radius: 4px;
  border: 1px solid var(--grey);
  width: 100%;
  padding: 8px;
}

.input {
  display: inline-block;
  width: 100%;
  margin-bottom: 16px;
  position: relative;

  &.required {
    label {
      &::after {
        content: ' *';
      }
    }
  }

  &.error {
    input {
      border-color: var(--red);
    }
  }

  label {
    height: 15px;
    font-size: 12px;
    position: absolute;
    top: -9px;
    left: 15px;
    user-select: none;
    background: var(--white);
    padding: 0 2px;
  }
}

.summary-widget {
  border-radius: 5px;
  border: 1px solid var(--grey);
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;

  @include max-large-screen {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: 0;
    border-radius: 0;
    border: none;
    display: none;

    &.open {
      display: flex;
      z-index: 95;
    }
  }

  .close-overlay {
    width: 100%;
    text-align: right;
    color: var(--linework);

    @include min-large-screen {
      display: none;
    }

    .icon {
      padding: 16px;
    }
  }

  .preview {
    max-width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    @include max-large-screen {
      border-radius: 5px;
      border: 1px solid var(--linework);
    }
  }

  .info {
    width: 100%;
    padding: 16px;

    .vehicle-title {
      font-weight: 600;
    }

    .vehicle-subtitle {
      color: var(--linework);
    }

    .line {
      border-top: 1px solid var(--linework);
      margin: 8px 0;
    }

    .price-table {
      > div {
        display: flex;
        justify-content: space-between;
      }
    }

    .more-details {
      display: none;
      color: var(--bodytext);

      &.active {
        display: block;
      }

      @include max-large-screen {
        display: block;
      }

      .discount {
        color: var(--green);
      }

      .subtotal {
        font-weight: 600;
      }

      .heading {
        font-weight: 600;
        margin-top: 8px;
      }

      .item {
        margin-left: 16px;
      }
    }

    .totals {
      display: flex;

      .price-table {
        flex: auto;
      }

      .more-details-button {
        margin-left: 8px;

        @include max-large-screen {
          display: none;
        }
      }

      .total-text {
        font-weight: 600;
        margin-right: 8px;
      }

      .vat-text {
        font-size: 0.8rem;
        margin-right: 8px;
      }

      .vat-info-icon {
        font-size: 0.8rem;
      }

      .price-total {
        font-weight: 600;
      }

      .down-payment {
        font-size: 0.8rem;
        color: var(--bodytext);
      }
    }

    .download-invoice {
      margin-bottom: -10px;
      font-size: 0.85rem;
      padding: 0;
      width: 100%;
    }
  }

  .overlay-actions {
    width: 100%;
    padding: 16px;

    @include min-large-screen {
      display: none;
    }

    .back {
      width: 100%;
    }
  }
}

.confirm-bank-transfer {
  h3 {
    margin-bottom: 16px;
  }

  .confirm-step {
    display: flex;
    margin-bottom: 32px;

    .icon {
      font-size: 2rem;
      flex: 0 0 32px;
      text-align: center;
      margin-right: 16px;
    }

    .heading {
      font-weight: 600;
    }
  }
}

.minimal-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;

  main {
    // 32px to account for padding, so the content will be $max-layout-width pixels.
    max-width: $max-layout-width + 32px;
    padding: 0 16px;
    width: 100%;
    align-self: center;
    flex: auto;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

// Base styles that apply to both a combobox and a listbox.
// This class should not be used directly, other than to extend it.
.box-base {
  display: inline-block;
  height: $input-height;
  position: relative;

  ul {
    position: absolute;
    z-index: 10;
    background-color: var(--white);
    border-radius: 4px;
    border: 1px solid var(--grey);
    list-style: none;
    padding-left: 0;
    margin: 4px 0 0;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;

    li {
      padding: 2px 16px;
      cursor: pointer;

      transition-property: background-color, color;
      transition-duration: 0.15s;
      transition-timing-function: ease-in-out;

      &.active {
        background-color: var(--blue);
        color: var(--white);
      }

      &.loader {
        text-align: center;
        cursor: initial;
      }
    }
  }
}

.payment-type-block {
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;

    h4 {
      display: flex;

      .title {
        flex: 1;
      }
    }

    .payment-dropdown {
      margin-top: 16px;
    }
  }
}

.payment-dropdown {
  width: 240px;

  .value {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      margin-left: 16px;
    }
  }

  ul.options {
    @include max-small-screen {
      position: fixed;
      z-index: 100;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-height: initial;
      margin: 0;
    }

    .title {
      padding: 16px;
      font-weight: 600;
      font-size: 1.2rem;

      @include min-small-screen {
        display: none;
      }
    }

    li {
      @include max-small-screen {
        font-size: 1.2rem;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
}

.loading-step-content {
  max-width: $max-layout-width + 32px;
  padding: 0 16px;
  width: 100%;
  align-self: center;
  flex: auto;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.vat-overview-modal {
  .modal-box {
    max-width: 900px;
  }

  .content {
    display: flex;

    @include max-large-screen {
      flex-direction: column;
    }
  }

  .vat-blocks {
    flex: auto;

    .vat-block {
      margin-bottom: 32px;

      .title {
        color: var(--blue-hover);
        font-weight: 600;
      }

      ul {
        list-style-type: none;
        margin: 8px 0;
        padding: 0;
      }

      .line {
        border-top: 1px solid var(--linework);
      }

      .prices {
        display: flex;
        justify-content: space-between;

        .price-label {
          font-weight: 600;
        }

        .vat-amount {
          text-align: right;
        }
      }
    }
  }

  .extra-info {
    margin: 16px 48px;
    padding: 8px 16px;
    width: 320px;
    background-color: var(--grey-background);
    border-radius: 4px;
    color: var(--bodytext);

    @include max-large-screen {
      flex-direction: column;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
    }

    ul {
      li {
        margin-bottom: 16px;

        .icon {
          font-size: 0.8rem;
          color: var(--black);
        }
      }
    }
  }
}

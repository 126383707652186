.pickup-service-block {
  .pickup-service-title {
    font-weight: 600;
    font-size: 16px;
    color: var(--black);
  }

  .options {
    padding: 0 20px 20px 40px;
  }

  .pickup-options {
    .pickup-content {
      padding: 14px 14px 14px 14px;

      .left-block {
        flex-basis: 65%;

        @include max-small-screen {
          padding-bottom: 8px;
        }

        .address {
          span {
            display: block;
          }

          .title {
            font-weight: 600;
          }
        }
      }

      .right-block {
        flex-basis: 35%;
        margin-top: auto;
      }
    }
  }

  .recommended-section {
    padding: 0 20px 20px 47px;

    .title {
      font-size: 16px;
      opacity: 1;
    }

    .title:first-letter {
      text-transform: uppercase;
    }

    .content {
      display: flex;
      padding-top: 8px;
      height: 24px;

      .transit-plates-text {
        line-height: 20px;
        padding-right: 8px;
      }

      .transit-plates-info {
        line-height: 20px;
        padding-left: 5px;
      }

      .transit-plates-amount {
        color: var(--blue-hover);
        padding-left: 56px;
        line-height: 20px;
      }
    }
  }

  .notification {
    margin: 0 20px 20px 47px;
  }
}

.configuration-steps {
  display: flex;
  // Account for the margins on the cards.
  max-width: $max-layout-width + 32px;
  margin: 32px auto 0;

  @include max-medium-screen {
    flex-direction: column;
    margin-top: 12px;
    padding: 0;
  }

  .card {
    display: flex;
    flex-direction: column;
    width: 33%;
    overflow: hidden;
    box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background: var(--white);
    margin: 16px;

    @include max-medium-screen {
      width: auto;
      flex-direction: column;
    }

    .card-body {
      padding: 1rem;
      display: flex;
      gap: 0.5rem;

      .title {
        padding-left: 5px;
        color: var(--bodytext);
        font-weight: 600;
      }

      p {
        text-align: left;
        font-size: 14px;
        letter-spacing: 0;
        opacity: 1;
        margin: 0;
        padding: 5px;
        color: var(--bodytext);

        &.read-more-text {
          @include min-small-screen {
            display: none;
          }
        }

        &.full-text {
          @include max-small-screen {
            display: none;
          }
        }
      }

      .card-icon {
        color: var(--bodytext);
      }
    }
  }
}

.payment-method-image {
  height: 36px;

  &.name {
    display: flex;
    align-items: center;

    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  &.badge {
    display: inline-flex;
    align-items: center;
    font-size: 0.9rem;
    margin: 4px;

    img {
      height: 36px;
    }

    &.bankTransfer {
      border: 1px solid var(--grey);
      border-radius: 4px;
      padding-right: 4px;
      height: 28px;

      img {
        height: calc(100% - 6px);
      }
    }
  }
}

.your-details-form {
  .subtitle {
    margin-top: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: var(--grey-60);
  }

  .email-text {
    color: var(--black);
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    width: 100%;

    @include max-small-screen {
      margin-bottom: 16px;
    }

    .text-input {
      @include max-small-screen {
        margin-bottom: 0;
      }
    }

    &.error {
      input {
        border-color: var(--red);
      }

      .icon {
        color: var(--red);
      }
    }
  }

  .notification {
    margin-top: 32px;
    margin-bottom: 32px;

    .icon {
      padding-left: 16px;
    }
  }

  .notification-error {
    margin: 0;
  }

  .have-account-text,
  .forgot-password-text {
    color: var(--blue);
    margin-left: 15px;
    margin-right: 5px;
    margin-top: 0;
    font-weight: normal;

    @include max-medium-screen {
      margin-top: 16px;
      text-align: center;
      width: 100%;
    }

    a {
      color: var(--blue);
      padding-left: 5px;
    }

    button {
      font-weight: 600;
      margin-left: 5px;
    }
  }

  .grecaptcha-badge {
    visibility: hidden;
  }

  .text-input {
    width: 100%;

    @include min-medium-screen {
      max-width: 350px;
    }

    label {
      display: inline-block;
      font-size: 16px;
      position: inherit;
      margin-bottom: 16px;
      left: unset;
      top: unset;
    }

    .right {
      top: unset;
    }
  }
}

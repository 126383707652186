.need-help {
  border-radius: 5px;
  border: 1px solid var(--grey);
  background-color: var(--white);
  margin-top: 16px;
  padding: 12px 16px 6px;

  @include max-large-screen {
    margin-top: 30px;
  }

  p {
    font-size: 0.875em;
    margin: 0;
  }

  .start-chat {
    font-size: 0.875em;
    height: 32px;
    padding: 0;

    &:hover {
      background-color: transparent;
      border-color: transparent;
    }
  }
}

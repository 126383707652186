.checkout-steps {
  display: flex;
  margin: 16px auto 32px;
  max-width: 1200px;
  width: 100%;

  .step {
    width: 20%;
    flex: 0 0 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    cursor: default;

    &.previous {
      cursor: pointer;
    }

    &.current {
      font-weight: bold;
    }

    &:first-child {
      .line:first-child {
        opacity: 0;
      }
    }

    &:last-child {
      .line:last-child {
        opacity: 0;
      }
    }

    .name {
      margin-bottom: 4px;
      color: var(--linework);
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;

      &.previous {
        color: var(--bodytext);
      }

      &.current {
        color: var(--green);
      }

      &.full {
        @include max-medium-screen {
          display: none;
        }
      }

      &.short {
        @include min-medium-screen {
          display: none;
        }

        @include max-small-screen {
          font-size: 10px;
        }
      }
    }

    .progress {
      width: 100%;
      display: flex;
      align-items: center;

      .number {
        flex: 0 0 24px;
        height: 24px;

        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 2px solid var(--grey);
        color: var(--grey);

        &.previous,
        &.current {
          color: var(--white);
          border-color: var(--green);
          background-color: var(--green);
        }
      }

      .line {
        flex: auto;
        border: 2px solid var(--grey);

        &.previous,
        &.current {
          border-color: var(--green);
          background-color: var(--green);
        }
      }
    }
  }
}

.company-list {
  margin-top: 8px;

  .items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;

    @include max-medium-screen {
      grid-template-columns: 1fr;
    }
  }

  .add-address {
    margin-top: 12px;
    margin-bottom: 16px;
  }
}

.company-option-block {
  border: 1px solid var(--grey);
  border-radius: 10px;
  display: flex;
  padding: 8px 0;
  cursor: pointer;
  transition: box-shadow 0.15s ease-in-out;

  &.active {
    border-color: var(--green);

    &.incomplete {
      border-color: var(--red);
    }
  }

  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  }

  .details {
    flex: 1;

    > div {
      line-height: 1.4rem;

      &:first-child {
        font-weight: 600;
      }
    }
  }

  .edit {
    // Add some padding to make it easier to click,
    // but not on top to preserve the layout.
    padding: 0 8px 8px;

    &:hover {
      color: var(--blue-hover);
    }
  }
}

.cookie-notification {
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  background-color: #fff;
  border-radius: 5px;
  text-align: left;
  font-size: 14px;
  position: fixed;
  padding: 15px;
  width: 220px;
  bottom: 12px;
  z-index: 99;
  left: 10px;

  a {
    color: var(--black);
  }

  .close {
    position: absolute;
    font-size: 12px;
    padding: 5px;
    right: 5px;
    top: 5px;
  }
}

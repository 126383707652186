footer {
  background-color: var(--grey-background);
  text-align: center;
  margin-top: 32px;
  padding: 24px 12px;

  .text {
    font-weight: bold;
    margin-bottom: 12px;
  }
}

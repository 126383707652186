.time-cost-info {
  padding: 5px 5px 5px 5px;
  border: 1px solid var(--grey);
  border-radius: 5px;
  display: flex;
  background-color: var(--grey-background);

  .time,
  .cost {
    width: 50%;
    padding-left: 7px;
    padding-right: 5px;

    span {
      display: block;

      &.days,
      &.amount {
        font-weight: 600;
      }

      &.amount {
        color: var(--blue-hover);
      }
    }
  }

  .time {
    border-right: 1px solid var(--grey);
  }
}

.checkout-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;

  &.has-overlay {
    overflow-y: hidden;
  }

  main {
    // 32px to account for padding, so the content will be $max-layout-width pixels.
    max-width: $max-layout-width + 32px;
    padding: 0 16px;
    width: 100%;
    align-self: center;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include max-large-screen {
      display: block;
    }

    > :first-child {
      flex: auto;
    }

    .right-side {
      flex: 0 0 350px;
      margin-left: 80px;

      @include max-large-screen {
        margin-left: 0;
      }
    }
  }

  .spacer {
    flex: auto;
    height: $input-height;
  }
}

.transport-step {
  .pickup-service-block,
  .delivery-service-block {
    border: 1px solid var(--grey);
    border-radius: 10px;
    margin-top: 16px;

    &.active {
      border: 1px solid var(--green);
    }

    .pickup-options,
    .delivery-options {
      border: 1px solid var(--grey);
      border-radius: 10px;

      @include max-small-screen {
        display: flex;
      }

      .delivery-content,
      .pickup-content {
        display: flex;
        flex-basis: 100%;

        @include max-small-screen {
          display: block;
        }

        .left-block {
          @include max-small-screen {
            width: auto;
            padding-right: 16px;
          }
        }

        .right-block {
          @include max-small-screen {
            width: auto;
            padding-left: 0;
          }
        }
      }
    }
  }

  .delivery-checkbox {
    margin-top: 10px;
  }

  .company-list + .notification {
    margin-bottom: 16px;
  }
}

.summary-header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid var(--grey);
  box-shadow: 0 0.1rem 0.5rem rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  position: sticky;
  top: 0;
  z-index: 80;

  @include min-large-screen {
    display: none;
  }

  .title {
    font-weight: 600;
  }

  .price {
    font-weight: 600;
    margin-right: 16px;
    color: var(--bodytext);
  }

  .view-text {
    color: var(--blue);
    cursor: pointer;
  }
}

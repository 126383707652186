.whats-next {
  display: flex;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;

  .content {
    flex: 0 1 $max-layout-width;
    margin-top: 64px;

    @include max-medium-screen {
      margin-top: 34px;
    }

    h3 {
      color: var(--black);
      font-size: 1.2rem;
      margin-bottom: 32px;
    }

    .steps {
      display: flex;

      @include max-medium-screen {
        flex-direction: column;
      }

      > div {
        flex: 20%;
        display: flex;
        flex-direction: column;

        @include max-medium-screen {
          flex-direction: row;
        }

        .line {
          position: relative;

          @include min-medium-screen {
            margin-bottom: 8px;
          }

          @include max-medium-screen {
            margin-right: 8px;
          }

          .icon {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: var(--green-disabled);
          }
        }

        &:not(:last-child) {
          .line {
            &::before {
              content: '';
              background-color: var(--green-disabled);
              position: absolute;
              z-index: -1;

              @include min-medium-screen {
                height: 4px;
                left: 0;
                right: 0;
                top: calc(50% - 2px);
              }

              @include max-medium-screen {
                width: 4px;
                top: 0;
                bottom: 0;
                left: calc(50% - 2px);
              }
            }
          }
        }

        .text {
          margin-bottom: 16px;
          padding-right: 16px;

          > :first-child {
            font-weight: 600;
          }

          p {
            color: var(--bodytext);
            margin-top: 0;
          }

          i {
            color: var(--green);
            font-style: normal;
          }
        }
      }
    }
  }
}

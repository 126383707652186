.documents-additional-block {
  .title {
    font-size: 18px;
  }

  p {
    line-height: 22px;
    margin: 0;
  }

  .documents-additional-list {
    margin-top: 30px;

    .documents-additional-item {
      //padding: 25px 10px 21px 15px;
      // TODO: When the "Documents Delivery" will be available - uncomment line above, remove "padding" and "checkbox styles below
      padding: 15px 10px 11px 15px;
      .bas.checkbox {
        margin-bottom: 0;
      }

      cursor: pointer;

      &.active {
        border-color: var(--green);
      }

      .text {
        user-select: none;
        margin-top: -3px;
      }

      .price {
        margin-top: -1px;
      }

      .recommended {
        background-color: var(--green-disabled);
        color: var(--green-hover);
        text-transform: capitalize;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        text-align: center;
        position: absolute;
        font-size: 0.85rem;
        font-weight: 600;
        padding: 0 10px;
        display: flex;
        height: 24px;
        z-index: 2;
        top: -12px;
      }
    }
  }

  .document-info-icon {
    margin-bottom: -5px;
    font-size: 14px;
    color: var(--blue);
    padding: 5px;
  }
}

.document-details-modal {
  &.modal {
    .modal-box {
      max-width: 600px;

      .content {
        .title-bar {
          margin-top: -55px;
          padding-left: 0;
        }
      }
    }
  }

  .description {
    margin-bottom: 15px;
  }

  .notice {
    font-weight: 600;
    font-size: 14px;
  }
}

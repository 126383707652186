.modal {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  .modal-box {
    background-color: var(--white);
    border: 1px solid var(--linework);
    border-radius: 5px;
    width: 80%;
    max-width: $max-layout-width;
    position: relative;
    overflow: auto;

    @include max-medium-screen {
      width: 100%;
      height: 100%;
    }

    .title-bar {
      color: var(--linework);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      padding-left: 16px;

      .title {
        color: var(--black);
        font-weight: 600;
        font-size: 20px;
      }

      .close {
        padding: 16px;
      }
    }

    > .content {
      padding: 0 16px 16px;
    }
  }
}

.delivery-service-block {
  .delivery-service-title {
    font-weight: 600;
    font-size: 16px;
    color: var(--black);
  }

  .options {
    padding: 0 20px 0 40px;
  }

  .delivery-options {
    margin-bottom: 16px;
    display: flex;

    .delivery-content {
      .left-block {
        flex-basis: 60%;

        .delivery-from,
        .delivery-to {
          padding-bottom: 5px;
          border-bottom: 1px solid var(--grey);

          span {
            &.title {
              display: flex;
              text-transform: uppercase;
              color: var(--linework);
              font-weight: 600;
              height: 21px;
            }

            &.address {
              opacity: 1;
              font-size: 14px;
              color: var(--black);
              padding-right: 5px;
            }
          }

          .includes-shipping-alert {
            color: var(--red);
          }
        }
      }

      .right-block {
        flex-basis: 40%;
        padding-right: 16px;
        padding-left: 21px;

        .available-transport {
          display: flex;
          padding: 10px 0 10px 5px;

          @include max-small-screen {
            padding: 0 0 10px 5px;
          }

          .transports,
          .incoterm {
            padding-right: 5px;
            color: var(--bodytext);
            font-weight: normal;
            font-size: 14px;
            height: 21px;
            padding-top: 16px;
            padding-bottom: 16px;
            line-height: 16px;
          }
        }
      }
    }
  }
}

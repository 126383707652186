.listbox {
  @extend .box-base;

  button {
    border-radius: 4px;
    border: 1px solid var(--grey);
    padding-left: 16px;
    padding-right: 16px;
    background-color: var(--white);
    height: 100%;
    cursor: pointer;

    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: var(--grey-background);
    }
  }
}

@mixin max-large-screen() {
  @media screen and (max-width: 1023px) {
    @content;
  }
}

@mixin min-large-screen() {
  @media screen and (min-width: 1024px) {
    @content;
  }
}

@mixin max-medium-screen() {
  @media screen and (max-width: 767px) {
    @content;
  }
}

@mixin min-medium-screen() {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin max-small-screen() {
  @media screen and (max-width: 640px) {
    @content;
  }
}

@mixin min-small-screen() {
  @media screen and (min-width: 641px) {
    @content;
  }
}

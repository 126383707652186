.text-input {
  &.has-right {
    input {
      padding-right: 40px;
    }
  }

  .right {
    position: absolute;
    right: 0;
    top: 0;
    width: $input-height;
    height: $input-height;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: var(--bodytext);
    background: none;
    border: none;

    &.clickable {
      cursor: pointer;
    }
  }
}
